/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, Image } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"Dekorace"}>
        <SiteHeader />

        <Column className="css-12y5do8 css-dq2krc --style2 --full" anim={""} name={"uvod"} animS={"3"} border={""} fullscreen={true} css={css`
      
    background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_s=350x_.jpg);
    @media (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_s=660x_.jpg);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_s=860x_.jpg);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_s=1400x_.jpg);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_s=2000x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_s=660x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_s=1400x_.jpg);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(var(--overlay-color), var(--overlay-color)), url(https://cdn.swbpg.com/t/4546/8459c7ef241e447ab2be337edc432853_s=3000x_.jpg);
    }
  
background-position: 50% 61%;
    `}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" anim={null} style={{"maxWidth":1390}} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" anim={"2"} animS={"5"} style={{"maxWidth":1425}}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"<span style=\"color: rgba(255, 255, 255, 0.63);\">Dekorace</span>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--30 pt--30" name={"Obsah"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box" content={"Ještě na tom pracujeme..."}>
              </Title>

              <Text className="text-box" content={"Omlouváme se, ale v současnosti pracujeme na této části webu.<br>Vraťte se prosím později a určitě zde naleznete co hledáte.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--20" name={"patka"} style={{"paddingTop":35,"backgroundColor":"rgba(224, 224, 224, 1)"}} layout={"l1"} css={css`
      background-position: 50% 50%;
    `}>
          
          <ColumnWrap className="column__flex --left el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box fs--30" content={"Ateliér Tůtojc Stod"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":394}} content={"Stella Jarošová<br>IČO:&nbsp;08663696<br>Sídlo:\nMířovice 10,\n333 01 Ves Touškov<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image alt={""} src={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":398}} srcSet={"https://cdn.swbpg.com/t/4546/6994423283334a92aa761f455601a59b_s=350x_.png 350w"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper >
            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}